<template>
  <div class="my-org-warp" :class="{'invite-mobile-warp' : !is_pc}">
    <div class="category-item">
      <h3 class="title">我加入的企业</h3>
      <div class="org-list">
        <div class="org-item" v-if="org_list.length == 0">
          <div class="main-info">
            <img src="/images/icon-agency.svg" />
            <span>暂无加入的企业</span>
          </div>
        </div>
        <div class="org-item" v-for="(org, i) in org_list" :key="i">
          <div class="main-info">
            <img src="/images/icon-agency.svg" />
            <span>{{org.name}}</span>
            <div class="enter-btn" @click="enterConsole(org)">进入工作台</div>
          </div>
          <div class="sub-info">
            <div class="sub-text-con">
              <div class="sub-text">企业内姓名：{{org.user_name}}</div>
              <div class="sub-text">企业内角色：{{userType(org.user_type)}}</div>
            </div>
            <!-- 暂时隐藏退出企业按钮 -->
            <!-- <div class="func-btn" v-if="!org.is_owner" @click="quitOpen(org)">
              <span>退出</span>
              <img src="/images/icon-exit.svg" alt="" srcset="">
            </div> -->
            <!-- <div class="func-btn" style="cursor: auto" v-else>
              <span>企业拥有者</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <a-modal title="退出企业"
      centered
      width="560px"
      v-model="quit_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="取消"
      okText="确定"
      :okButtonProps="{props: {disabled: !name_check} }"
      @ok="nameConfirm"
      >
        <div class="modal-text" style="margin-bottom: 16px">你正在退出企业「{{org_info.name}}」，请在下方输入完整的企业名称“{{org_info.name}}”以确认操作。</div>
        <a-form-model ref="nameForm" :model="nameForm" :rules="ruleInline">
          <a-form-model-item label="" prop="name">
            <a-input v-model="nameForm.name" :placeholder="org_info.name"/>
          </a-form-model-item>
        </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'myOrg',
  created () {
    this.getMyOrg()
  },
  data () {
     const validateName = async(rule, value, callback) => {
      this.name_check = false
      if(value !== this.org_info.name) {
        callback(new Error('企业全称输入错误'))
      } else {
        this.name_check = true
        callback()
      }
    }
    return {
      name_check: false,
      org_info: {},
      org_list: [],
      nameForm: {
        id: '',
        name: ''
      },
      ruleInline: {
        name: [
          { required: true, validator: validateName, trigger: 'blur' }
        ],
      },
      quit_show: false
    }
  },
  computed: {
    ...mapState({
      app_domain: state => state.app_domain
    })
  },
  methods: {
    async getMyOrg() {
      let res = await OrgService.myOrganize({ filter_has_product: 1 })
      if (res) {
        this.org_list = res
      }
    },
    userType (key) {
      // 用户类型：1普通成员，2-管理员，3-主管理员
      let role = '-'
      switch (key) {
        case 1:
          role = '普通成员'
          break;
        case 2:
          role = '管理员'
          break;
        case 3:
          role = '主管理员'
          break;
        default:
          break;
      }
      return role
    },
    quitOpen(item) {
      this.nameForm = {
        name: '',
        id: item.id
      }
      this.org_info = item
      this.quit_show = true
      this.$nextTick(() => {
        this.$refs.nameForm.resetFields()
      })
    },
    nameConfirm() {
      this.$refs.nameForm.validate(async valid => {
        if (valid) {
          let res = await OrgService.quitOrganize({
            organize_id: this.nameForm.id
          })
          if (res) {
            this.getMyOrg()
            this.quit_show = false
          }
        }
      })
    },
    async enterConsole (org) {
      window.open(`${this.app_domain.organization_center_url}?org_id=${org.id}`)
    }
  }
}
</script>